/*! 2025-4-2 20:17:05 */
[data-v-8b7e8a32]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.import-dialog .row[data-v-8b7e8a32]{display:flex;margin-bottom:20px}.import-dialog .row dt[data-v-8b7e8a32]{font-size:20px;color:#666}.import-dialog .row dd[data-v-8b7e8a32]{flex:1}.import-dialog .row .el-link[data-v-8b7e8a32]{font-size:16px;line-height:32px}.import-dialog .row .wait-upload[data-v-8b7e8a32]{display:flex;max-width:100%;align-items:center;margin-top:10px}.import-dialog .row .wait-upload .label[data-v-8b7e8a32]{margin-right:10px}.import-dialog .row .wait-upload .name[data-v-8b7e8a32]{font-size:14px;color:#333}.import-dialog .row .wait-upload .icon-close[data-v-8b7e8a32]{color:#ccc;cursor:pointer;font-size:16px;font-weight:bold;margin-left:10px}.import-dialog .row .wait-upload .icon-close[data-v-8b7e8a32]:hover{color:#1890ff}.import-dialog .row .desc[data-v-8b7e8a32]{font-size:14px;color:#999;line-height:24px;margin-top:4px}.import-dialog .tips[data-v-8b7e8a32]{font-size:14px;color:#999;line-height:24px}.import-dialog .dialog-footer[data-v-8b7e8a32]{text-align:center}


.error-alert {
  min-width: 610px;
}

[data-v-4281d748]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.base-info-wrapper em[data-v-4281d748]{display:inline-block;width:85px}.base-info-wrapper span[data-v-4281d748]{display:inline-block}

[data-v-410f56de]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.oper-sticky[data-v-410f56de]{width:calc(100% - 105px);display:flex;justify-content:center;position:fixed;bottom:0;border-top:1px solid #ccc;height:80px;align-items:center;background-color:#fff}.pro-button[data-v-410f56de]{border-color:#2882ff;color:#2882ff;margin:15px 0}.add-box[data-v-410f56de]{overflow:hidden;overflow-y:hidden;height:calc(100vh - 145px);margin-bottom:100px !important}.add-box.audit-box[data-v-410f56de]{height:auto !important}em[data-v-410f56de]{color:#000}.el-form-item[data-v-410f56de]{margin-bottom:0px !important}.lot-wrapper[data-v-410f56de]{display:flex;align-items:center}.lot-wrapper .el-input[data-v-410f56de]{flex:1}.lot-wrapper .el-icon-circle-plus-outline[data-v-410f56de]{margin-left:5px;cursor:pointer;font-size:20px;color:#2882ff}.inline-block[data-v-410f56de]{display:inline-block}.mr20[data-v-410f56de]{margin-right:20px}[data-v-410f56de] .el-form-item__error{display:none}[data-v-410f56de] .col-remark .vxe-cell{overflow:visible !important}


.error-alert {
  min-width: 610px;
}


/*# sourceMappingURL=chunk-d121ea10.c532bc12.css.map*/